import React, { useState, useRef, useEffect } from "react";

import "./style.css";
import { LoadingOutlined, SoundOutlined, SoundFilled } from "@ant-design/icons";

const VideoPlayer = (props) => {
  const {
    src,
    src_portada,
    vIsLoadingTemario,
    sendInfo,
    vDataLeccion,
    openNotification,
    setvIsLoadingEvaluacion,
  } = props;

  const intervaloAutosave = 1000 * 30; // 10 segundos, por ejemplo

  const [vFirstPlay, setvFirstPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);

  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);

  const [volume, setVolume] = useState(100);
  const [isMuted, setIsMuted] = useState(false);
  const [vIsHoverVolumne, setvIsHoverVolumne] = useState(false);
  const [sliderVisible, setSliderVisible] = useState(false);
  const [isAdjustingVolume, setIsAdjustingVolume] = useState(false);

  const [isFullScreen, setisFullScreen] = useState(false);

  const [vIsLoading, setvIsLoading] = useState(true);
  const [vIsHover, setvIsHover] = useState(false);

  const [vIsHoverLineaProgress, setvIsHoverLineaProgress] = useState(false);
  const [vLineaProgresshover, setvLineaProgresshover] = useState(false);

  const [buffered, setBuffered] = useState(0);

  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);

  const handleWaiting = () => {
    setvIsLoading(true);
  };

  const handlePlaying = () => {
    setvIsLoading(false);
  };

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    const handleBlur = () => {
      if (videoRef.current !== null) {
        sendInfo(videoRef.current.currentTime, videoRef.current.duration);
      }
    };

    const handleFocus = () => {
      if (videoRef.current !== null) {
        sendInfo(videoRef.current.currentTime, videoRef.current.duration);
      }
    };

    const handleBeforeUnload = (e) => {
      if (videoRef.current !== null) {
        sendInfo(videoRef.current.currentTime, videoRef.current.duration);
      }
    };

    const handleError = (e) => {
      openNotification(
        "Error del video",
        "Se produjo algún tipo de error durante la carga del video, refresca la pagina si es necesario.",
        "error"
      );
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("beforeunload", handleBeforeUnload);
    const intervalId = setInterval(() => {
      if (isPlaying) {
        sendInfo(videoRef.current.currentTime, videoRef.current.duration);
      }
    }, intervaloAutosave);

    if (videoRef.current !== null) {
      videoRef.current.addEventListener("error", handleError);
    }

    // Función de limpieza
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (videoRef.current !== null) {
        videoRef.current.removeEventListener("error", handleError);
        sendInfo(videoRef.current.currentTime, videoRef.current.duration);
      }
    };
  }, [isPlaying, intervaloAutosave, videoRef]); // Dependencias actualizadas

  // Función para enviar información

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    const progress =
      (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };
  const handleSeeked = () => {
    // Esta función se ejecutará después de que el usuario haya buscado en el video.
    // Aquí puedes agregar cualquier lógica adicional que necesites después de una acción de búsqueda.
    // Por ejemplo, podrías querer reanudar la reproducción si el video estaba en play antes de buscar.
    if (isPlaying) {
      videoRef.current.play();
    }
  };

  const mRestaurarProgreso = () => {
    videoRef.current.currentTime = vDataLeccion.currentTimeLeccionActual;
    setCurrentTime(vDataLeccion.currentTimeLeccionActual);
    const progress =
      (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };

  const handleMetadataLoaded = () => {};

  const handlePlayPause = () => {
    if (!vFirstPlay) {
      setvFirstPlay(true);
    }
    props.sendInfo(videoRef.current.currentTime, videoRef.current.duration);
    if (videoRef.current.paused) {
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          setIsPlaying(true);
        }).catch(error => {
          console.error('Error al reproducir el video:', error);
        });
      }
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (value) => {
    setVolume(value);
    videoRef.current.volume = value / 100;
    setIsMuted(value === 0);
  };

  const toggleMute = () => {
    if (videoRef.current.volume > 0) {
      videoRef.current.volume = 0;
      setVolume(0);
      setIsMuted(true);
    } else {
      videoRef.current.volume = 1;
      setVolume(100);
      setIsMuted(false);
    }
  };

  const formatTime = (seconds) => {
    // Calculamos las horas, minutos y segundos
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600; // Resto después de calcular las horas
    let minutes = Math.floor(seconds / 60);
    seconds %= 60; // Resto después de calcular los minutos

    // Devolvemos el tiempo en formato HH:mm:ss
    // Usamos padStart para asegurarnos de que cada unidad tenga al menos dos dígitos
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toFixed(0).padStart(2, "0")}`;
  };

  const handleAdd10seconds = (e) => {
    const video = videoRef.current;
    if (video && video.currentTime < video.currentTime + 10 < video.duration) {
      video.currentTime += 10;
      sendInfo(video.currentTime, video.duration);
    }
  };
  const handleSubtract10Seconds = (e) => {
    const video = videoRef.current;
    if (video) {
      // Asegúrate de que el tiempo actual no sea menor a 10 segundos.
      // Si lo es, simplemente vuelve al inicio del video.
      video.currentTime = Math.max(0, video.currentTime - 10);
      sendInfo(video.currentTime, video.duration);
    }
  };

  const handleProgressChange = (value) => {
    const newTime = (value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  };

  const handleFullscreen = () => {
    sendInfo(videoRef.current.currentTime, videoRef.current.duration);
    if (isFullScreen) {
      exitFullscreen();
    } else {
      if (videoContainerRef.current) {
        if (videoContainerRef.current.requestFullscreen) {
          videoContainerRef.current.requestFullscreen();
        } else if (videoContainerRef.current.mozRequestFullScreen) {
          /* Firefox */
          videoContainerRef.current.mozRequestFullScreen();
        } else if (videoContainerRef.current.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          videoContainerRef.current.webkitRequestFullscreen();
        } else if (videoContainerRef.current.msRequestFullscreen) {
          /* IE/Edge */
          videoContainerRef.current.msRequestFullscreen();
        }
        setisFullScreen(true);
      }
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari & Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
    setisFullScreen(false);
  };

  const VolumeControl = () => (
    <div
      className="volume-container"
      onMouseEnter={() => setSliderVisible(true)}
      onMouseLeave={() => {
        if (!isAdjustingVolume) {
          setSliderVisible(false);
        }
      }}
    >
      <div onClick={toggleMute} className="volume-icon">
        {isMuted ? (
          <SoundOutlined className="control-icon" />
        ) : (
          <SoundFilled className="control-icon" />
        )}
      </div>
      <div className={sliderVisible ? "volume-slider active" : "volume-slider"}>
        <input
          type="range"
          min="0"
          max="100"
          value={volume}
          onChange={(e) => handleVolumeChange(e.target.value)}
          onMouseDown={() => setIsAdjustingVolume(true)}
          onMouseUp={() => {
            setIsAdjustingVolume(false);
          }}
          orient="vertical"
          style={{ writingMode: "bt-lr" }} // para navegadores que no soportan orient
        />
      </div>
    </div>
  );

  const updateBuffered = () => {
    if (videoRef.current && videoRef.current.buffered.length) {
      const bufferedEnd = videoRef.current.buffered.end(
        videoRef.current.buffered.length - 1
      );
      const duration = videoRef.current.duration;
      setBuffered((bufferedEnd / duration) * 100);
    }
  };

  if (vIsLoadingTemario) {
    return (
      <div className="video-container-v">
        <div className="video-loading">
          <LoadingOutlined className="loading-icon" />
        </div>
      </div>
    );
  }

  const handleClick = (event) => {
    if (!vIsLoading) {
      // Obtiene la posición X del clic relativa al elemento
      const x = event.nativeEvent.offsetX;

      // Obtiene el ancho total de la barra
      const totalWidth = event.currentTarget.offsetWidth;

      // Calcula el porcentaje
      const percentage = (x / totalWidth) * 100;
      handleProgressChange(percentage);
      sendInfo(videoRef.current.currentTime, videoRef.current.duration);
    }
  };
  const handleMouseMoveLineaProgress = (event) => {
    // Obtiene la posición X del clic relativa al elemento
    const x = event.nativeEvent.offsetX;

    // Obtiene el ancho total de la barra
    const totalWidth = event.currentTarget.offsetWidth;

    // Calcula el porcentaje
    const percentage = (x / totalWidth) * 100;

    setvLineaProgresshover(percentage);
  };

  const handleLoadStart = () => {
    setvIsLoadingEvaluacion(true);
  };

  const handleCanPlay = () => {
    console.log(
      "El video puede comenzar a reproducirse, pero podría detenerse"
    );
  };

  const handleStalled = () => {};

  const handleSuspend = () => {};

  const handleAbort = () => {};
  const handleEnded = () => {
    setIsPlaying(false);
    setvFirstPlay(false);
    openNotification(
      vDataLeccion.isContinuar
        ? "Terminaste de repasar el video!"
        : "Terminaste de ver el video!",
      vDataLeccion.isContinuar
        ? `Continua con la leccion ${vDataLeccion.numLeccionSiguiente}`
        : `Puedes continuar con tu evaluación`,
      "success"
    );
  };

  const handleLoadedData = () => {
    setvIsLoading(false);
    setvIsLoadingEvaluacion(false);
    setDuration(videoRef.current.duration);
    mRestaurarProgreso();
    // Otras acciones cuando el video está listo
  };

  return (
    <div
      ref={videoContainerRef}
      className={`curso-alumno-video`}
      onMouseEnter={() => setvIsHover(true)}
      onMouseLeave={() => {
        if (isPlaying) {
          sendInfo(videoRef.current.currentTime, videoRef.current.duration);
        }
        setvIsHover(false);
      }}
    >
      <video
        className="video"
        ref={videoRef}
        src={src}
        controls={false}
        onLoadedData={handleLoadedData}
        onLoadedMetadata={handleMetadataLoaded}
        onWaiting={handleWaiting}
        onPlaying={handlePlaying}
        onProgress={updateBuffered}
        onTimeUpdate={handleTimeUpdate}
        onSeeked={handleSeeked}
        onCanPlay={handleCanPlay}
        onLoadStart={handleLoadStart}
        onStalled={handleStalled}
        onSuspend={handleSuspend}
        onAbort={handleAbort}
        onEnded={handleEnded}
      />
      {!vFirstPlay && (
        <img
          src={src_portada}
          className="portada video"
          loading="lazy"
          alt=""
        />
      )}

      {vIsLoading && (
        <div className="video-loading">
          <LoadingOutlined className="loading-icon" />
        </div>
      )}
      {vIsHover && (
        <div class="controles-container">
          <div class="video-arriba">
            {/*
          <img
              src={`${window.location.origin}/images/Vectors-Wrapper_68.svg`}
              loading="lazy"
              width="20"
              height="20"
              alt=""
              class="vectors-wrapper-84"
            />
          */}
          </div>
          <div class="video-centro">
            {!vIsLoading && (
              <>
                <div class="icon-10" onClick={handleSubtract10Seconds}>
                  <img
                    src={`${window.location.origin}/images/menos10.png`}
                    loading="lazy" alt="" class="image-17"
                  />
                  <div class="icon-10-text">10</div>
                </div>
                <div class="frame-130" onClick={handlePlayPause}>
                  {!isPlaying ? (
                    <img
                      src={`${window.location.origin}/images/Vectors-Wrapper_110.svg`}
                      loading="lazy"
                      width="53.54621887207031"
                      height="53.54621887207031"
                      alt=""
                      class="vectors-wrapper-153"
                    />
                  ) : (
                    <>
                      <img
                        src={`${window.location.origin}/images/Vectors-Wrapper_69.svg`}
                        loading="lazy"
                        width="14"
                        height="42"
                        alt=""
                        class="vectors-wrapper-88"
                      />
                      <img
                        src={`${window.location.origin}/images/Vectors-Wrapper_69.svg`}
                        loading="lazy"
                        width="14"
                        height="42"
                        alt=""
                        class="vectors-wrapper-86"
                      />
                    </>
                  )}
                </div>
                <div class="icon-10" onClick={handleAdd10seconds}>
                  <img
                    src={`${window.location.origin}/images/mas10.png`}
                    loading="lazy"
                    width="27.708332061767578"
                    height="26.25"
                    alt=""
                    class="vectors-wrapper-88"
                  />
                  <div class="icon-10-text">10</div>
                </div>
              </>
            )}
          </div>
          <div class="video-abajo">
            <div class="div-block-13">
              <div class="text-block-4">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
              <div class="div-block-15">
                {VolumeControl()}
                {/*
                
                <div
                  className="volume-container"
                  onMouseEnter={() => setvIsHoverVolumne(true)}
                  onMouseLeave={() => setvIsHoverVolumne(false)}
                >
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_68.svg`}
                    loading="lazy"
                    width="15"
                    height="14.616667747497559"
                    alt=""
                    class="vectors-wrapper-82"
                  />

                  <div
                    className={`volume-slider ${
                      vIsHoverVolumne ? "active" : ""
                    }`}
                  >
                    <input
                      type="range"
                      min={0}
                      max={100}
                      value={volume}
                      onChange={handleVolumeChange}
                    />
                  </div>
                </div>
                */}
                <div class="icon-2 fullscreen" onClick={handleFullscreen}>
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_67.svg`}
                    loading="lazy"
                    width="11.666666030883789"
                    height="11.666666030883789"
                    alt=""
                    class="vectors-wrapper-83"
                  />
                </div>
              </div>
            </div>
            <div
              class="div-block-12"
              onClick={handleClick}
              onMouseEnter={() => setvIsHoverLineaProgress(true)}
              onMouseLeave={() => setvIsHoverLineaProgress(false)}
              onMouseMove={handleMouseMoveLineaProgress}
            >
              <div class="v-line-load" style={{ width: `${buffered}%` }} />
              {vIsHoverLineaProgress && (
                <div
                  class="v-line-hover"
                  style={{ width: `${vLineaProgresshover}%` }}
                />
              )}
              <div class="v-line-progress" style={{ width: `${progress}%` }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
