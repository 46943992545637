export const V_URL_APi_STRAPPI = "https://80--main--dev-e-lisa-v1--leandroidex--ovsqf8n90cli2.pit-1.try.coder.app/";
export const V_URL_APi_PYTHON = "https://80--main--dev-e-lisa-v1--leandroidex--ovsqf8n90cli2.pit-1.try.coder.app/api2/";
export const V_URL_API =
  window.location.origin === "https://443--main--dev-e-lisa-v1--leandroidex--ovsqf8n90cli2.pit-1.try.coder.app"
    ? "https://80--main--dev-e-lisa-v1--leandroidex--ovsqf8n90cli2.pit-1.try.coder.app"
    : window.location.origin;

export const vFrames = {
  modal_login: "login",
  modal_singup: "singup",
  home: "home",
  courses: "courses",
  about_us: "about us",
  course_not_purchased: "course_not_purchased",
  course_alumno: "course_alumno",
  course_not_purchased_alumno: "course_not_purchased_alumno",
  selected_menu: {
    home: "home",
    courses: "courses",
    course_not_purchased: "course_not_purchased",
  },
  alumno: {
    mis_cursos: "mio",
    home: "",
    perfil: "perfil",
    curso_no_comprado: "cnc",
  },
  profesor: {
    mis_cursos: "mio",
    home: "",
    perfil: "perfil",
  },
};
